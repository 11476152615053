import { Reducer } from "redux"
import { AnalysisOptionsState, AnalysisOptionsActionTypes, SampleAnalysisState } from "./analysis-options-types"
import { logObject } from "src/core/utils"

export const DEFAULT_SAMPLE_STATE: SampleAnalysisState = {
    selectedPipeline: null
}

const initialState: AnalysisOptionsState = {
    isRunLoaded: false,
    isSavingRun: false,
    sampleStates: [DEFAULT_SAMPLE_STATE],
}

export const analysisOptionsReducer: Reducer<AnalysisOptionsState> = (
    state: AnalysisOptionsState = initialState,
    action: any
) => {
    switch (action.type) {
        case AnalysisOptionsActionTypes.SET_IS_RUN_LOADED:
            return Object.assign({}, state, {
                isRunLoaded: action.payload
            })
        case AnalysisOptionsActionTypes.SET_IS_SAVING_RUN:
            return Object.assign({}, state, {
                isSavingRun: action.payload
            })
        case AnalysisOptionsActionTypes.SET_SAMPLE_STATES:
            return Object.assign({}, state, {
                sampleStates: action.payload
            })

        case AnalysisOptionsActionTypes.SET_SAMPLE_STATE: {
            const { sampleState, index } = action.payload
            // preserve ref to state.sampleStates so that only subform i rerenders.
            const newSampleStates =  state.sampleStates.map( (currentState, i) => {
                return i === index ? sampleState : currentState
            })
            return {
                ...state,
                sampleStates:newSampleStates
            }
            
        }
        case AnalysisOptionsActionTypes.INSERT_SAMPLE_STATE: {
            const { sampleState, index } = action.payload
            const newSampleStates = [
                ...state.sampleStates.slice(0, index), 
                sampleState,                       
                ...state.sampleStates.slice(index),   
              ];
            logObject(newSampleStates, "inserting newSampleStates")
            return {
                ...state,
                sampleStates: newSampleStates
            }
        }
        case AnalysisOptionsActionTypes.DELETE_SAMPLE_STATE: {
            const { index } = action.payload
            return {
                ...state,
                sampleStates: state.sampleStates.splice(index, 1)
            }
        }
        case AnalysisOptionsActionTypes.SET_SAMPLE_PIPELINE: {
            const { pipeline, index } = action.payload
            // preserve ref to state.sampleStates so that only subform i rerenders.
            return {
                ...state,
                sampleStates: state.sampleStates.map( (sampleState, i) => {
                    return i === index ? {...sampleState, pipeline} : sampleState
                })
            }   
        }
        case AnalysisOptionsActionTypes.SET_SAMPLE_EPS: {
            const { entryPointSelections, index } = action.payload
            // preserve ref to state.sampleStates so that only subform i rerenders.
            return {
                ...state, 
                sampleStates: state.sampleStates.map( (sampleState, i) => {
                    return i === index ? {...sampleState, entryPointSelections} : sampleState
                })
            }
        }
        case AnalysisOptionsActionTypes.CLEAR_ALL: {
            return initialState
        }
        default: {
            return state
        } 
    }
}
